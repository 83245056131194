

export default {
    name: "consent",
    mounted(){ 

            if (typeof(window.cookieconsent) !== "undefined") {

                let vm = this;

                window.cookieconsent.run(
                    {
                        "notice_banner_type":"simple",
                        "consent_type":"express",
                        "palette":"dark",
                        "language":"en",
                        "page_load_consent_levels":[
                            "strictly-necessary"
                        ],
                        "notice_banner_reject_button_hide":false,
                        "preferences_center_close_button_hide":false,
                        "page_refresh_confirmation_buttons":false,
                        "website_name":"https://www.future-plus.co.uk",
                        "website_privacy_policy_url":"https://www.future-plus.co.uk/privacy-policy","callbacks": {
                        "scripts_specific_loaded": (level) =>
                            {
                                switch(level) {
                                    case 'targeting': 
                                        vm.gtag('consent', 'update', {
                                            'ad_storage': 'granted',
                                            'ad_user_data': 'granted',
                                            'ad_personalization': 'granted',
                                            'analytics_storage': 'granted'
                                        });
                                        break;
                                }
                            }
                        },
                        "callbacks_force": true
                    }
                ); 

                window.dataLayer = window.dataLayer || [];
         
                this.gtag('consent', 'default', {
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'analytics_storage': 'denied'
                });
                
            }
    },
    methods: {
        gtag(){  
            dataLayer.push(arguments);
        }
    },
    head() {
    return {
        script: 
        [
                {
                    src: 'https://www.termsfeed.com/public/cookie-consent/4.1.0/cookie-consent.js'
                },

            ]
        }
    }
}

